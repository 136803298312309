/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css";

:root {
    --orange-color: #ef7f42;
    --brown-color: #5f544f;
    --light-gray-color: #f3f0ec;
    --dark-gray-color: #bfbebb;
    --white-color: #FFF;
}
.oranged {
    background-color: var(--orange-color) !important;
}
.browned {
    background-color: var(--brown-color) !important;
}

.color-brown {
    color: var(--brown-color);
}

.grise {
    background-color: var(--light-gray-color);
    height: 100%;
    min-height: 90vh;
}

.shadow-bottom {
    box-shadow: 0 0 15px 0 #000;
}
.padded{
    padding: 15px 15px 0 15px;
}

.bordered {
    border-bottom: 1px var(--brown-color) solid;
}

h4{
    margin: 0;
}

.bordered-bold {
    border-bottom: 3px var(--brown-color) solid;
}

.menu-link{
    border-bottom: 1px var(--brown-color) solid;
    background: #fff;
    margin: 0 0 0 15px;
    padding-left: 30px;
}

.card  {
    margin: 10px 0;
    box-shadow: 2px 2px 8px 0px var(--dark-gray-color);
    border: 1px var(--brown-color) solid;
    width: 230px;
    height: 230px;
    display: inline-flex;
}

.card:hover {
    border: 5px var(--orange-color) solid;
    cursor: pointer;
    color: var(--orange-color) !important;
}

.card:hover .color-brown{
    color: var(--orange-color) !important;
}

.big-icon {
    width: 100px !important;
    height: 100px;
}

.form-bg{
    background-color: var(--light-gray-color);
    border-bottom: 2px var(--brown-color) solid;
}

.form-title {
    color: #fff;
}

.left-link, .pointer {
    cursor: pointer;
}

.left-link:hover {
    color: var(--orange-color) !important;;
}

.linky:active {
    color: var(--orange-color) !important;;
}

.no-margin {
    padding-left: 0;
    padding-right: 0;
    margin: 0;
}

.control-label{
	/*display: table-cell;*/
	margin-bottom: 0;
	height: 28px;
	vertical-align: middle;
	font-weight: bold;
	font-size: small;
	color: var(--brown-color);
}
*.form-control:not(textarea)  {
	height: 28px;
	/*height: auto !important;*/
	line-height : 1;
	font-size: unset;
	appearance: auto !important;
}
*.form-control:is(select)  {
	padding: 0px 12px;
}
.form-control::placeholder {
    opacity: .5 !important;
}

textarea.form-control{
	font-size: unset !important;	
}

.confirm-btn {
    background-color: var(--orange-color);
    color: var(--brown-color);
}

.table th, .table td {
    padding: 0;
}

h5.modal-title {
    border-bottom: none;
}

/************************ CSS serge***************************/
.btn-footer{
    display: flex;
    align-items: right;
    flex-direction:row;
    justify-content: right;
}

.table tr , .table td , .table th{
    padding: 0 !important;
}

.petit-icon{
    width: 100px !important;
    height: 30px;
    color: aliceblue;
    cursor: pointer;
}

.icon-animate{
    /*animation: anim ease 1s;*/
    transform: rotate(180deg);
}

@keyframes anim{
    100%{transform: rotate(180deg);}
}

.voir-doss{
    color: #0051ff;
    cursor: pointer;
}
/********** Ajout Article ***************************/

.mini-box-wrapper{
	position: relative;	
}
.mini-box{
	position: absolute;
	top: 0;
	left: 0;
	max-height: calc(70vh - 20px);
	border: 1px var(--dark-gray-color) solid;
	border-radius: .25rem;
	box-shadow: 0 0 5px 0 var(--dark-gray-color);
	background: #fff;
	overflow: hidden;
	z-index: 999;
}
.mini-box .result_wrapper{
	height: -moz-available;
	overflow: auto;	
	border: 1px #f00 solid;
}
.selected-part,.list{
	border-bottom: 1px var(--dark-gray-color) solid;	
}
.chap-wrapper{
	overflow: auto;
}
.list:not(.selected){
	cursor: pointer;
}
.list:not(.selected):hover{
	background: var(--light-gray-color);	
}
.list.selected{
	color: var(--brown-color);	
}
.mini-col{
	width: 5%;
    flex: 0 0 5%;
}
.hs_resume{
	min-height: 28px !important;
	height: max-content !important;	
}
@media (min-width: 576px){
	#disposition .modal-dialog {
		max-width: 80vw !important;
	}
	
	#disposition .modal-content {
		max-height: calc(100vh - 50px) !important;
		overflow: auto;
	}
	
	#disposition .dispo_box{
		overflow: auto;
		max-height: -webkit-fill-available;
	}
	
	#disposition .sticky{
		/*position: sticky;
		top: 10px;*/
	}
}
.bg-light-brown{
	background: var(--light-gray-color);
}

.full-height {
	 height: calc(100vh - 60px);
	 overflow: auto;
}

/* TST */
.hsdisposition .sticky-left{
	position: sticky;
	top: 5px;	
}
.hsdisposition .chapitre{
	margin-bottom: 5px;
		
}
.hsdisposition .chapitre:not(:last-child){
	border-bottom: 1px var(--brown-color) solid;	
}
.hsdisposition .searchResultBox{
	color: var(--brown-color);
	border-bottom: 1px var(--brown-color) solid;
}
.hsdisposition .searchResultBox .searchResult{
	cursor: pointer;
}
.hsdisposition .searchResultBox .searchResult:hover{
	background: var(--light-gray-color);
}
.hsdisposition .dispoTitle{
	color: var(--brown-color);
	cursor: pointer;
}

.hsdisposition .btnCol{
	padding: 10px;
	margin-bottom: 5px;
	background: var(--light-gray-color);
	border: .5px #5f544fcc solid;
	border-radius: 5px;
}

.hsdisposition .dispoTitle:hover{
	background: var(--light-gray-color);
}
.right .dispoTitle{
	padding: 10px;
	border: 1px var(--brown-color) solid;
	border-radius: 5px 5px 0 0;
}
.right .dispoTitle:hover{
	color: var(--light-gray-color);
	background: var(--brown-color);
}
.hsdisposition .caret{
	font-weight:bold;	
}
.hsdisposition .caret:before{
	content: "^";
	display: inline-block;
	width: 20px;
	height: 20px;
	text-align:center;
	font-weight:bold;
	font-stretch:expanded;
	transition: .5s all ease;
}
.hsdisposition .dispoTitle .caret:before{
	transform: rotate(0deg);	
}
.hsdisposition .dispoTitle .collapsed .caret:before{
	transform: rotate(180deg);	
}
.dispo_box, .dispo{white-space: pre-wrap };

.dispo .selected{
	/*font-weight: normal !important;*/
	background: #FFC !important;	
}
/************************************************/
/** currency **/
.currency{
	margin-bottom: 10px;	
}
.currency .curlist{
	margin: 10px;
	padding: 5px;
	color: #999;
	border: 1px #ccc solid !important;
	border-radius: 5px;
}
.currency .curlist:first-child {
	color: #222 !important;
	font-weight: bold;
	
}
.currency_1 .curlist:first-child{
	border-color: #030 !important;
	box-shadow: 1px 2px 2px #060;	
}

.currency_0 .curlist:first-child{
	border-color: #900 !important;
	box-shadow: 1px 2px 2px #900;	
}

.currvalue{
	font-size: 1.5em; 	
}

.currCtrl{
	display: inline-block;
	margin: 0px 10px;
}

.dataTable tbody tr:nth-child(2n+1){
	background: #cccc;
}
.dataTable tbody tr:hover{
	background: rgba(102,255,204,.2);
}
/***** Auto Complete ************/

.autocomplete {
	/*the container must be positioned relative:*/
	position: relative;
	display: inline-block;
}
input {
	border: 1px solid transparent;
	background-color: #f1f1f1;
	padding: 10px;
	font-size: 16px;
}
input[type=text] {
	background-color: #f1f1f1;
	width: 100%;
}
input[type=submit] {
	background-color: DodgerBlue;
	color: #fff;
}
.autocomplete-items {
	position: absolute;
	border: 1px solid #d4d4d4;
	border-bottom: none;
	border-top: none;
	z-index: 99;
	/*position the autocomplete items to be the same width as the
container:*/
	top: 100%;
	left: 0;
	right: 0;
}
.autocomplete-items div {
	padding: 10px;
	cursor: pointer;
	background-color: #fff;
	border-bottom: 1px solid #d4d4d4;
}
.autocomplete-items div:hover {
	/*when hovering an item:*/
	background-color: #e9e9e9;
}
.autocomplete-active {
	/*when navigating through the items using the arrow keys:*/
	background-color: DodgerBlue !important;
	color: #ffffff;
}
.searchBar{
	position: sticky;
	top: 0px;
	padding: 15px;
	box-shadow: 0px 2px 5px #ccc;
	background: #fff;
	z-index: 9999;
		
}
.closeSearch{
	float: left;
	margin-right: 15px;
	content: "×";
	font-weight: bold;
	font-size: 15px;
	cursor: pointer;
}